import React, { useEffect } from "react"
import ProfilePic from '../assets/profile_pic.jpeg';
 
function Home(props) {
  useEffect(() => {
    document.title = "Home"
  }, []);

  return (
    <div className="App">
      <header>
        <img src={ProfilePic} alt="Avatar" className="App-Avatar"/>
        <h1 className="App-Text">Hi, I'm Caleb 👋</h1>
      </header>
      <p className="App-Text">
        Currently building memes and dreams as an iOS Engineer at Twitch. Previously CTO at Map2Next: a mobile app that provides the fastest way to find great places to go. Prior to joining Map2Next, I worked as an iOS engineer with companies across the US where I created apps which are now being used by thousands of users.
      </p>
    </div>
  )
}
 
export default Home;