// Import the BrowserRouter, Route and Link components
import { BrowserRouter, Route } from 'react-router-dom';
import Home from './components/Home.js';
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Route exact path="/" component={Home} />
      </div>
    </BrowserRouter>
  );
}

export default App;
